import { Button, Dialog, FormControlLabel, IconButton, Switch, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import AutocompleteField from "elements/AutocompleteField";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ApiRequest } from "GlobalFunctions";

export default function MovementEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, movement_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    warehouse_id: "",
    document_number: "",
    comments: "",
    movement_type: "outgoing",
    concept_id: "",
    user_id: "",
    products: [] as any,
    is_web: true,
    company_movement_id: "",
}


  const [formValues, setFormValues] = useState(defaultValues);

  /*=========================================================
  SWITCH IS PURCHASE
  =========================================================*/
  const [isPurchase, setIsPurchase] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsPurchase(isChecked);
    setFormValues({
      ...formValues,
      movement_type: isChecked ? "incoming" : "outgoing",
      concept_id: isChecked ? "purchase" : formValues.concept_id, // Ajusta según sea necesario
    });
  };


  useEffect(() => {
    if (movement_id) {

      ApiRequest({
        url: "/inventorymovements/details",
        method: "get",
        headers: {
          ltkn: localStorage.getItem("ltkn"),
        },
        query: {
          movement_id,
        },
        setResponse: (response: any) => {
          setFormValues(response.data);
        },
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [movement_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [list, setList] = useState<any[]>([]);
  /*=========================================================
  GET LIST
  =========================================================*/
  const getList = async () => {
    await ApiRequest({
      url: "/products/list",
      method: "get",
      headers: {
          ltkn: localStorage.getItem('ltkn')
      },
      query: {
          full_list: true,
      }, 
      setResponse: (response: any) => {
          setList(response.list);
      },
    });
  }

  useEffect(() => {
    getList();
  }, []);


  /*===============================================================
  SUBMIT FORM
  ===============================================================*/
  const handleSubmit = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    await ApiRequest({
      url: "/inventorymovements/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  const productDefault = {
    product_id: null,
    package_quantity: "",
    units: ""
  }


  const addProduct = () =>{
    console.log("addProduct")
    
    let newProducts = formValues.products;
    newProducts.push(productDefault);
    setFormValues({...formValues, products: newProducts});
    
  }


  const getReport = async (download = "") => {
    console.log(formValues);
    setLoading(true);
  

    await ApiRequest({
      url: "/reports/getReport",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        movement_id,
        exportType: "getMovement",
        download,
      },
      setResponse: (response: any) => {
        if (response.status === "success") {
          if (download === "pdf") {
            window.open(response.pdfUrl, "_blank");
          }
          if (download === "excel") {
            window.open(response.fileUrl, "_blank");
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
        
      },
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
      <div className="DialogContainer" >
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Movimiento de Inventario {movement_id ? `#${formValues.company_movement_id}` : ""}</h2>
              <span className="h2_subtitle">Editar</span>
            </div>
          </div>

          <div className="input_group">

          <FormControlLabel
            control={
              <Switch checked={isPurchase} onChange={handleSwitchChange} />
            }
            label="Es una compra"
          />

            <AutocompleteWarehouses
              value={formValues.warehouse_id}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  warehouse_id: e.target.value,
                });
              }}
            />

            {!isPurchase && (
             <AutocompleteField 
                label="Tipo de movimiento"
                endpoint="/inventorymovements/list_concept"
                value={formValues.concept_id}
                onChange={(data: any) => {
                    setFormValues({...formValues, movement_type: data.movement_type, concept_id: data.value});
                }}
              />
            )}

            {formValues.movement_type === 'incoming' && (
            <TextField
              id="document_number"
              name="document_number"
              label="Número de documento"
              type="text"
              required
              fullWidth
              value={formValues.document_number}
              onChange={handleInputChange}
            />
            )}
              

            <TextField
              id="comments"
              name="comments"
              label="Comentarios"
              multiline
              rows={4}
              type="text"
              required
              fullWidth
              value={formValues.comments}
              onChange={handleInputChange}
            />
          </div>

          <div className="productList">
            <h3>Productos</h3>
            <div className="productListItems">
              {formValues.products.map((product: any, index: number) => {

                let quantitiesText = '';
                quantitiesText += product.package_quantity > 0 ? `Cajas: ${product.package_quantity} ` : '';
                quantitiesText += product.units > 0 ? `Unidades: ${product.units}` : '';

                quantitiesText = (product.quantity)? `x ${product.quantity}` : quantitiesText
                return (
                <div key={index} className="productListItem">
                  <div className="productListItemName">
                    <AutocompleteField
                      label="Producto"
                      renderValues={["product_code", "label"]}
                      value={product.product_id}
                      preloadedList={list}
                      onChange={(data: any) => {
                        let newProducts = formValues.products;
                        newProducts[index] = {
                          ...newProducts[index],
                          product_id: data.value,
                          product_info: data,
                        };
                        setFormValues({ ...formValues, products: newProducts });
                      }}
                    />
                  </div>
                  <TextField
                    id="package_quantity"
                    name="package_quantity"
                    label="Cajas"
                    type="number"
                    required
                    value={product.package_quantity}
                    onChange={(e) => {
                      let newProducts = formValues.products;
                      newProducts[index] = {
                        ...newProducts[index],
                        package_quantity: e.target.value,
                      };
                      setFormValues({ ...formValues, products: newProducts });
                    }}
                  />

                    <TextField
                    id="units"
                    name="units"
                    label="Unidades"
                    type="number"
                    required
                    value={product.units}
                    onChange={(e) => {
                      let newProducts = formValues.products;
                      newProducts[index] = {
                        ...newProducts[index],
                        units: e.target.value,
                      };
                      setFormValues({ ...formValues, products: newProducts });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addProduct();
                      }
                    }}

                  />

                    {/* delete row*/}
                    <DeleteOutlineOutlinedIcon 
                      onClick={() => {
                        let newProducts = formValues.products;
                        newProducts.splice(index, 1);
                        setFormValues({ ...formValues, products: newProducts });
                      }}
                    />
                </div>
              )})}
              {}
              <div className="addProductButton">
              <IconButton 
                aria-label="add" 
                onClick={addProduct} 
                sx={{ 
                  backgroundColor: "#1C3B41", 
                  padding: '10px', 
                  color: '#fff', 
                  '&:hover': { backgroundColor: '#24545d' } 
                }}
                disabled={formValues.warehouse_id === ""}
              >
                <AddIcon />
              </IconButton>
              </div>
              
            </div>
          </div>

          <div className="dialogButtons">
            <Button onClick={() => setOpenEditor(false)} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Guardar
            </Button>

            <Button 
              variant="outlined"
              onClick={() => getReport("pdf")}
            >
              Exportar PDF
            </Button>

            <Button 
              variant="outlined"
              onClick={() => getReport("excel")}
            >
              Exportar Excel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
