import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ApiRequest, validateForm } from 'GlobalFunctions';
import AutocompleteField from "elements/AutocompleteField";
import PaymentResult from './PaymentResult';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

const defaultFormValues = {
  invoices: [],
  payment_method_id: "",
  amount: "",
  discount: 0,
  retefuente: 0,
  reteica: 0,
  payment_code: "",
  image: "",
  message: "",
};

export default function PaymentDialog(props: any) {
  const { open, setOpen, ltkn, selectedInvoices, setSelectedInvoices, getInvoices, closeInvoiceListDialog } = props;
  const [formValues, setFormValues] = useState<any>(defaultFormValues);
  const [showPaymentResult, setShowPaymentResult] = useState(false);
  const [payment_id, setPaymentId] = useState<string | null>(null);

  const [date, setDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      setFormValues({ ...formValues, invoices: selectedInvoices });
    }
  }, [selectedInvoices]);

  const submitForm = async () => {
    const validate = await validateForm(formValues, {
      amount: "Valor",
      payment_code: "No. Recibo",
    });
    if (!validate.isValid) return alert(validate.message);

    if (formValues.amount <= 0) {
      return alert("El valor debe ser mayor a 0");
    }

    if (formValues.amount > selectedInvoices.reduce((acc: any, item:any) => acc + item.total_debt, 0)) {
      return alert("El valor no puede ser mayor al total de las facturas");
    }

    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    
    if (formValues.image) {
      body.append("image", formValues.image);
    }

    await ApiRequest({
      url: "/payments/addEdit",
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data',
        ltkn: localStorage.getItem('ltkn'),
      },
      body,
      setResponse: (response: any) => {
        setPaymentId(response.payment_id);
        setShowPaymentResult(true); // Mostrar el resultado del pago y ocultar el formulario
      },
      setError: (error: any) => {
        console.error(error);
      },
    });
  };

  const callback = () => {
    setOpen(false);
    setFormValues(defaultFormValues);
    getInvoices();
    setSelectedInvoices([]);
    setShowPaymentResult(false); // Reiniciar el estado para que el formulario aparezca la próxima vez
    closeInvoiceListDialog();
  };

  return (
    <Dialog open={open} onClose={callback} fullWidth maxWidth="md">
      <DialogTitle>
        {showPaymentResult ? "Resultado del Pago" : "Pagar Facturas"}
        <IconButton
          aria-label="close"
          onClick={callback}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showPaymentResult ? (
          <PaymentResult
            open={showPaymentResult}
            setOpen={setShowPaymentResult}
            ltkn={ltkn}
            callback={callback}
            payment_id={payment_id}
          />
        ) : (
          <>
            <div id='paymentDialogContent'>
              <div id='paymentsList'>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Factura</TableCell>
                        <TableCell align="right">Saldo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedInvoices.map((invoice: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{invoice.invoice_code}</TableCell>
                          <TableCell align="right">${invoice.amount_due.toLocaleString()}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell><strong>Total</strong></TableCell>
                        <TableCell align="right"><strong>${selectedInvoices.reduce((acc: any, item:any) => acc + item.amount_due, 0).toLocaleString()}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
              </div>
              <div id='paymentForm' className='input_group'>
                <div className="input_row">
                  <AutocompleteField
                    label="Forma de pago"
                    endpoint="/payments/paymentMethodsList"
                    value={formValues.payment_method_id}
                    onChange={(data: any) => setFormValues({ ...formValues, payment_method_id: data.value })}
                    ltkn={ltkn}
                  />

                  <TextField
                    label="Valor"
                    variant="outlined"
                    value={formValues.amount}
                    onChange={(e) => setFormValues({ ...formValues, amount: e.target.value })}
                    fullWidth
                    type="number"
                  />
                </div>

                <div className="input_row">
                  <TextField
                    label="Descuento"
                    variant="outlined"
                    value={formValues.discount}
                    onChange={(e) => setFormValues({ ...formValues, discount: e.target.value })}
                    fullWidth
                    type="number"
                  />

                  <TextField
                    label="ReteFuente"
                    variant="outlined"
                    value={formValues.retefuente}
                    onChange={(e) => setFormValues({ ...formValues, retefuente: e.target.value })}
                    fullWidth
                    type="number"
                  />

                  <TextField
                    label="ReteICA"
                    variant="outlined"
                    value={formValues.reteica}
                    onChange={(e) => setFormValues({ ...formValues, reteica: e.target.value })}
                    fullWidth
                    type="number"
                  />
                </div>

                <TextField
                  label="No. Recibo"
                  variant="outlined"
                  value={formValues.payment_code}
                  onChange={(e) => setFormValues({ ...formValues, payment_code: e.target.value })}
                  fullWidth
                />

                <TextField
                  label="Mensaje"
                  variant="outlined"
                  value={formValues.message}
                  onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                  fullWidth
                  multiline
                  rows={3}
                />

                <DatePicker
                  label="Fecha"
                  value={date}
                  onChange={(newValue: any) => {
                    setDate(newValue);
                    setFormValues({ ...formValues, return_date: moment(newValue).format('YYYY-MM-DD') });
                  }}
                  renderInput={(params: any) => <TextField {...params} margin="normal" fullWidth />}
                />
              </div>
            </div> 
          </>
        )}
      </DialogContent>
      {!showPaymentResult && (
        <DialogActions>
          <Button onClick={callback} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (window.confirm("¿Está seguro de realizar esta acción?")) submitForm();
            }}
            color="primary"
            variant="contained"
          >
            Pagar facturas
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
